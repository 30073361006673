/**
 *  import optionsFactory from './options'
 */

// exports

export const Mobylette = {
  name: 'mobylette',
  el: '#mobylette',
  options: {
    // selectors
    $mainContainer: '#search-list-page--filters-container',
    $filterContainer: '#search-list-page--filters',
    $closeBtn: '#search-list-page--filters-close > .close',
    $mainBtn: '#search-list-page--filters-btn > .btn',
    $updateBreadCrumb: '.filtredby--breadcrumb',
    // selector out of $element $[A-Z]
    $Window: window,
    $Body: 'body',
    // default options
    maxWidth: 720,
    updatableArea: {
      $searchListResults: '.row-fluid.product-cat.product-faq.product-faq-results.search-list',
      $totalResults: '.row-fluid.bloc.bloc-seo .total-results',
      $updateResultsTarget: '#filtredTotalResults',
      $updateBreadCrumb: '.filtredby--breadcrumb'

    },
    scrollOptions: {
      autohidemode: 'hidden',
      iframeautoresize: true,
      cursoropacitymin: 0
    }

  },
  state: {
    lastRequest: null
  },
  methods: {
    open () {
      if (!this.isOpen()) {
        this.$mainBtn.text(this.$mainBtn.data('text-opened'))
        this.$mainContainer.addClass('in')
        this.$Body.addClass('no-overflow')
      } else {
        this.$mainBtn.text(this.$mainBtn.data('text-closed'))
        this.reload()
      }
    },
    close () {
      window.enableGlobalScroll()
      this.$mainContainer.removeClass('in')
      this.$Body.removeClass('no-overflow')
    },
    updateScrroll () {
      if (this.$Window.width() <= this.options.maxWidth) {
        $('#search-list-page--filters-container').scrollTop(0)
      }
    },
    debounce: function (func, wait, immediate) {
      var timeout
      return function () {
        var context = this
        var args = arguments
        var later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    },
    // updateScrroll(){
    //     if (this.$Window.width() <= this.options.maxWidth) {
    //         let windowHeight = $(window).height() - $("#search-list-page--filters-btn").outerHeight();
    //         if($("#search-list-page--filters-container").length){
    //             $("#search-list-page--filters-container").css('height', windowHeight)
    //             $("#search-list-page--filters-container").getNiceScroll().resize()
    //         }
    //     }
    // },
    isOpen () {
      window.disableGlobalScroll()
      return this.$mainContainer.is('.in')
    },
    filter (event) {
      if (this.$Window.width() <= this.options.maxWidth) {
        console.log('filter', event)
        event.preventDefault()
        event.stopPropagation()
        $.get('?' + $(event.target).serialize())
          .done((data) => {
            this.lastRequest = $(event.target).serialize()
            // update filters
            this.$filterContainer.html($(data).find(this.options.$filterContainer).html())
            this.$filterContainer.find('.facetValues form').submit((event) => this.filter(event))
            var checkedFilters = $('#mobylette input:checkbox:checked').map(function () {
              return $(this).data('facet-code')
            }).get()
            var activeBreadcrumbs = $('.crumbs .crumb a[data-facet-code]').map(function () {
              return $(this).data('facet-code')
            }).get()
            var difference = activeBreadcrumbs.filter(x => !checkedFilters.includes(x))
            if (difference.length) {
              difference.forEach(function (elementToDelete) {
                $(".crumbs .crumb a[data-facet-code='" + elementToDelete + "']").parent().remove()
              })
            }

            let updatedButn = $(data).find(this.options.$mainBtn).data('text-opened')
            this.$mainBtn
              .html(updatedButn)
            this.$mainBtn.data('text-opened', updatedButn)
            this.updateScrroll()
          })
        return false
      }
    },
    updatePageContent (data) {
      // update page content
      for (let selector in this.options.updatableArea) {
        $(this.options.updatableArea[selector])
          .html($(data).find(this.options.updatableArea[selector]).html())
      }
    },
    reload (event) {
      if (this.lastRequest) {
        const historyEntry = {
          type: 'search-list-page-history-entry',
          data: null,
          title: document.title,
          url: window.location.pathname + '?' + this.lastRequest
        }
        $.get('?' + this.lastRequest)
          .done((data) => {
            try {
              // update page content
              this.updatePageContent(data)

              // Manipulate history
              historyEntry.data = data
              history.pushState(historyEntry, historyEntry.title, historyEntry.url)
            } catch (e) {
              console.error(e)
              window.location.href = historyEntry.url
            }
          })
      }
      this.close()
    },
    popState (event) {
      if (event.state && event.state.type === 'search-list-page-history-entry') {
        this.updatePageContent(event.state.data)
      }
    }
  },
  install (GSEB, options) {
    // do something ...
    console.log('mobylette module loading ...')
    this.$closeBtn.on('click', (event) => this.close(event))
    this.$mainBtn.on('click', (event) => this.open(event))
    $(document).on('click', '#search-list-page--filters .show-all a', (e) => {
      if (this.$Window.width() <= this.options.maxWidth) {
        this.$mainBtn.click()
      } else {
        window.location.reload()
      }
    })
    var initiateMobyletteScroll = new PerfectScrollbar('#search-list-page--filters-container')// eslint-disable-line no-unused-vars
    this.$filterContainer.find('.facetValues form').submit((event) => this.filter(event))
    window.addEventListener('popstate', (event) => this.popState(event), false)
  }
}

export default Mobylette
