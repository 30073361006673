import Logger from './logger'
import Module from './module'

export const GSEB = $.extend(window.GSEB, (() => {
  return {
    modules: [],
    use (module, options) {
      if (this.modules.findIndex((mod) => mod.name === module.name) < 0) {
        this.modules.push(new Module(module, options))
      }
    },
    bootstrapping (modules) {
      console.log('GSEB bootstrapping module', modules)
      modules.imports.forEach((module) => {
        if (Array.isArray(module)) {
          this.use(module[0], module[1])
        } else {
          this.use(module)
        }
      })
    }
  }
})())

export const console = Logger
