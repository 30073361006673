
/**
 * Class: Observable
 * Extend: Deferred
 *
 */

export default class Observable {
  constructor () {
    $.extend(this, $.Deferred())
  }

  next (value) {
    if (this.skip || this.skipAll) {
      this.skip = (value === 'skip')
      this.skipAll = this.skipAll && (value !== 'activate')
      return this
    }
    if (value === 'skip' || value === 'skipAll') {
      this[value === 'skip' || value === 'skipAll' ? value : 'isActive'] = true
      return this
    }
    return this.notify(value)
  }

  subscribe (callback) {
    return this.progress(function (value) {
      callback.call(this, value)
    })
  }
}

window.$observables = {} || window.$observables
window.Observable = Observable

export const $observables = window.$observables
