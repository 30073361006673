
export default window.console = $.extend({}, window.console, ((_console) => {
  window._console = _console
  return {
    log (...args) {
      if (!GSEB.debug) return
      _console.log.apply(_console, args)
    },
    info (...args) {
      if (!GSEB.debug) return
      _console.info.apply(_console, args)
    },
    warn (...args) {
      if (!GSEB.debug) return
      _console.warn.apply(_console, args)
    },
    error (...args) {
      if (!GSEB.debug) return
      _console.error.apply(_console, args)
    },
    debug (...args) {
      if (!GSEB.debug) return
      _console.debug.apply(_console, args)
    }
  }
})(window.console))
