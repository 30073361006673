import { GSEB } from '@gseb/core'
import Observable, { $observables } from '@gseb/helpers/observable'
import { Mobylette as krupsMobylette } from '@gseb/modules/mobylette'
import { MyModule } from './modules/exemple-module'

console.log('main.js')

$observables.mainObservable = new Observable()

const MainModule = {
  imports: [
    MyModule,
    krupsMobylette
  ]
}

// load a single module
// GSEB.use(MyModule)

// load main modules
GSEB.bootstrapping(MainModule)
