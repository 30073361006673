// use external modules
/*
import module from '../module-name'
import module2 from '../module2-name'
import sharedModule from '@shared-module'
 */

// use private dependencies
/*
import optionsFactory from './options'
 */

// exports

export const MyModule = {
  name: 'MyModule',
  state: {
    title: 'test 1'
  },
  methods: {
    setTitle (value) {
      window._console.log(this.title)
      this.title = value
      return this
    }
  },
  install (GSEB, options) { /*
    // do something ...

    console.log('module loading ...')
    GSEB.constants.myconstant = 'exmple'
    $('#something').doWhatYouNeed2Do()
    GSEB.use(sharedModule, optionsFactory.make(options)) */
    console.log('module loading ...')
  }
}

export default MyModule
