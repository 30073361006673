
export default class Module {
  constructor (module, options) {
    this.name = 'name'

    // The root DOM element that the module instance is managing.
    const $element = $((module.options ? module.options.el : undefined) || module.el || document)
    Object.defineProperty(this, '$element', {
      // Create a new getter for $element property
      get () {
        return $element
      }
    })

    // load state/method ... from module definition
    $.extend(this, module.methods || {}, (function () {
      delete module.methods
      return module
    })())

    // create getter/setter ... for module state
    Object.keys(module.state || {}).forEach(function (prop) {
      Object.defineProperty(this, prop, {
        // Create a new getter for the property
        get () {
          return module.state[prop]
        },
        // Create a new setter for the property
        set (value) {
          module.state[prop] = value
        }
      })
    }, this)

    // create getter ... for DOM Elements
    Object.keys(module.options || {}).forEach(function (prop) {
      if (prop.startsWith('$')) {
        const $elem = ((selector) => prop.match(/^\$[A-Z]/g) ? $(selector) : this.$element.find(selector))(module.options[prop])
        Object.defineProperty(this, prop, {
          // Create a new getter for the property
          get () {
            return $elem
          }
        })
      }
    }, this)

    // init module
    if (this.$element.length) { this.install(GSEB, options) }

    return this
  }

  install (GSEB, options) {
    console.log(this.name, ' module loading ...')
  }
}
